// contact form
(($) => {
  const contactForms = document.querySelectorAll(".wpcf7");
  const siteHeader = document.querySelector(".site__header");
  const siteHeaderHeight = siteHeader ? siteHeader.offsetHeight : 0;

  for (let i = 0; i < contactForms.length; i++) {
    const form = contactForms[i];

    form.addEventListener("wpcf7submit", (e) => {
      $("html, body").animate({
        scrollTop:
          e.target.getBoundingClientRect().top +
          window.scrollY -
          siteHeaderHeight,
      }, 300);

      const inputs = e.detail.inputs;

      if (inputs.some(input => input.name === 'reservation_id')) {
        const htmlBody = document.querySelector('body');
        const avgFields = ['url_score', 'checkbox-2', 'checkbox-3'];
        let avgCount = 0;
        let avgSum = 0;
  
        inputs.forEach(function(input) {
          if (avgFields.includes(input.name)) {
            avgCount++;
            avgSum += +input.value.slice(0,1);
          }
        });

        if ((avgSum/avgCount) >= 4) {
          htmlBody.classList.add('review-great');
        }
      }

    });
  }

})(jQuery);

// wp gutenberg gallery lightbox
jQuery(".wp-block-gallery .blocks-gallery-item a")
  .attr("data-lightbox", "gallery")
  .addClass("gallery__item-link plus-overlay");

// attractions page
function showAttractionBox(target) {
  jQuery(target + " .attraction__description").addClass("active");
  jQuery(target + " .attraction__info .more span.text").toggle();
}

(($) => {
  const autoOpenShortDescriptions = (maxHeight) => {
    $(".attractions .attraction__description").each(function () {
      let childrenHeight = 0;

      $(this)
        .children()
        .each(function () {
          childrenHeight += $(this).height();
        });

      if (childrenHeight < maxHeight) {
        $(this).addClass("active");
        $(this).siblings(".more").remove();
      }
    });
  };

  let containerMaxHeight = $(".attractions .attraction__description:not(.active)").css("max-height");

  if (containerMaxHeight !== undefined) {
    containerMaxHeight = parseInt(containerMaxHeight.replace(/\D/g, ""), 10);
    const maxHeight = parseInt(containerMaxHeight * 1.25, 10);
    autoOpenShortDescriptions(maxHeight);
  }

  $(document).ready(() => {
    $("body").css({
      "padding-top":
        parseInt($("body").css("padding-top")) +
        $(".info_topbar").outerHeight(true) +
        "px",
    });

    $(".owl-carousel.opinion__box").owlCarousel({
      items: 1,
      loop: true,
      margin: 0,
      nav: true,
      dots: false,
      navText: [$(".slider__arrow--prev"), $(".slider__arrow--next")],
      responsive: {
        0: {
          items: 1,
        },
        900: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });

    $(".attractions .attraction__box .more").click(function () {
      $(this).prev().toggleClass("active");
      $("span.text", this).toggle();
    });

    $(".home .news__items-box .item").click(function () {
      $(this).toggleClass("lead");
    });

    $('.info_popup .close').on('click', function() {
      $(this).parent().remove();
      document.cookie = 'info-status=1; secure; samesite; expires=Sun, 1 Jan 2099 00:00:00 UTC; path=/';
    });

    $(".news .news__items-box").slick({
      vertical: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      verticalSwiping: true,
      prevArrow: $(".news .slider__arrow--prev"),
      nextArrow: $(".news .slider__arrow--next"),
    });
  });
})(jQuery);

// fixed header
(() => {
  const header = document.querySelector(".site__header");
  const bodyPadding = document.body.style.paddingTop;

  const toggleHeader = () => {
    let position = window.pageYOffset;

    if (position > bodyPadding) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  };

  window.addEventListener("scroll", toggleHeader);
  window.addEventListener("load", toggleHeader);
  document.addEventListener("DOMContentLoaded", toggleHeader);
})();
